import {ChangeDetectionStrategy, Component, Input} from '@angular/core'
import {AvatarSize} from '../../../../craft/avatar/avatar.component'

@Component({
  selector: 'bgo-buildigo-avatar',
  templateUrl: './buildigo-avatar.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BuildigoAvatarComponent {
  @Input() protected size: AvatarSize = 'xs'
}
