<div
  class="cft-component-president flex min-h-3 min-w-3 items-center justify-center rounded-full text-center leading-6 text-white"
  [ngClass]="{'h-[18px] w-[18px]': size === 'xs'}"
  *transloco="let t"
>
  <img
    class=""
    [ngClass]="{'h-[10px] w-[10px]': size === 'xs'}"
    ngSrc="assets/images/buildigo-icon-transparent-neg.svg"
    [alt]="t('shared.avatarAltText')"
    height="30"
    width="31"
  />
</div>
